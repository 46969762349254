/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "RobotoRegular";
  src: url("assets/fonts/Roboto-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "RobotoBold";
  src: url("assets/fonts/Roboto-Bold.ttf") format('truetype');
}

#trial-message {
  display: none;
}

div, h1, h2, h3, h4, h5, h6 {
  font-family: "RobotoRegular";
}

div strong {
  font-family: "RobotoBold";
}

div Strong {
  font-family: "RobotoBold";
}

#loading-screen {
  background-color: rgba(25, 25, 25, 0.7);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999;
  margin-top: 0;
  top: 0;
  text-align: center;
}
#loading-screen img {
  width: 100px;
  height: 100px;
  position: relative;
  margin-top: -50px;
  margin-left: -50px;
  top: 50%;
}
#loading-screens {
  background-color: rgba(25, 25, 25, 0.7);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999;
  margin-top: 0;
  margin-left: -1%;
  top: 0;
  text-align: center;
}
#loading-screens img {
  width: 100px;
  height: 100px;
  position: relative;
  margin-top: -50px;
  margin-left: -50px;
  top: 50%;
}
.colorNav {
  background-color: #002860;
  color: white;
}
.colorUTPLBotones {
  background-color: #eaab00;
  color: #003e71;
}

.colorUTPLModal {
  background-color: #003e71;
  color: white;
}

.colorUTPLModal:hover {
  background-color: #eaab00;
  color: #003e71;
}

.colorUTPLModalUser {
  background-color: #003e71;
  color: white;
}

.colorUTPLModalUser:hover {
  background-color: #eaab00;
  color: #003e71;
}

.icons-color {
  color: white;
  font-size: 200%;
}
.icons-color:hover {
  color: #eaab00;
  font-size: 200%;
}

.colorCard {
  background-color: #eaab00;
  color: #003e71;
}

.ColorBien {
  color: #eaab00;
}

.ColorMal {
  color: red;
}


.vrt-align{
  vertical-align: middle;
}

.vrt-align-center{
  text-align: center;
  vertical-align: middle;
}

.colorUTPLBotton{
  color: #003e71;
  border-color:#003e71 ;
}

.colorUTPLBotton:hover {
  background-color: #eaab00;
  color: white;
}

.colorUTPLBottonDpl{
  background-color: #003e71;
  color:white ;
}

.colorUTPLBottonDpl:hover {
  background-color: #eaab00;
  color: white;
}

.colorUTPLBottonDpl2{
  background-color: #eaab00;
  color:#003e71;
}

.colorUTPLBottonDpl2:hover {
  background-color: #003e71;
  color: white;
}

.titlePage {
  font-size: 150%;
}
